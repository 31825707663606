import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "components/Button";
import Error from "components/Error";
import FormField from "components/FormField";
import { loginUser, LOGIN_FAILURE } from "actions";
import { formatUiErrors, useQueryParams } from "utils";
import { useAuthDispatch, useAuthState } from "context";
import FormWrapper from "components/FormWrapper";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Invalid password")
    .required("Password is required"),
});

const Login = () => {
  const { errorMessage } = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    accountConfirmationSuccess,
    alreadyConfirmed,
    orgAuthorizationSuccess,
    orgAlreadyConfirmed,
  } = useQueryParams();

  const { from } = location.state || { from: { pathname: "/" } };
  const [uiError, setUIError] = useState(undefined);
  const [uiMessage, setUIMessage] = useState(undefined);

  const { mutate: loginRequest, isLoading, error } = useMutation(
    (payload) => loginUser(dispatch, payload),
    {
      onSuccess: () => {
        dispatch({ type: LOGIN_FAILURE, error: null });
        history.replace(from);
      },
    }
  );

  useEffect(() => {
    if (accountConfirmationSuccess === "true" || alreadyConfirmed === "true") {
      setUIMessage("Your email address has been confirmed.");
    }
  }, [accountConfirmationSuccess, alreadyConfirmed]);

  useEffect(() => {
    if (orgAuthorizationSuccess === "true" || orgAlreadyConfirmed === "true") {
      setUIMessage("Your organization has been authorized.");
    }
  }, [orgAuthorizationSuccess, orgAlreadyConfirmed]);

  useEffect(() => {
    setUIError(errorMessage || error?.message);
  }, [error, errorMessage]);

  return (
    <FormWrapper>
      <h1 className="h5 text-center">Login</h1>

      {uiError && <Error className="mb-4">{formatUiErrors(uiError)}</Error>}
      {uiMessage && (
        <div className="mb-4 pl-6 pr-4 py-3 border-teal bg-teal-opacity-15 border-l-6">
          {uiMessage}
        </div>
      )}

      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={loginRequest}
        validateOnMount
      >
        {({ dirty, isValid }) => (
          <>
            <Form data-testid="login-form">
              {/* Email */}
              <div className="mb-6">
                <FormField
                  label="Email"
                  name="email"
                  id="email"
                  data-testid="email"
                  required
                />
              </div>

              {/* Password */}
              <div className="mb-6">
                <FormField
                  label="Password"
                  type="password"
                  name="password"
                  id="password"
                  data-testid="password"
                  required
                />
              </div>

              <Button
                disabled={isLoading || !isValid || !dirty}
                isLoading={isLoading}
                loadingText={<>Logging In&hellip;</>}
                className="w-full mb-4"
                type="submit"
              >
                Log in
              </Button>
            </Form>

            <div className="flex space-x-4 justify-center items-center text-sm">
              <Link to="/register">Register</Link>
              <Link to="/forgot-password">Forgot Password</Link>
            </div>
          </>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default Login;
